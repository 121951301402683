import classnames from "classnames";
import styles from "./Button.module.scss"

const Button = ({text, color, onClick, className, textColor}) => {
    return (
        <div onClick={onClick}
             className={classnames(className, styles.button, styles[textColor], {
                 [styles[color]]: styles[color],
             })}>
            {text}
        </div>
    );
};

export default Button;