import SectionOne from "../home/sections/sectionOne/SectionOne";

const Aboutus = () => {
    return (
        <div>
            <SectionOne/>
        </div>
    );
};

export default Aboutus;