import styles from './SectionThree.module.scss'

const SectionThree = () => {
    return (
        <div className={styles.root}>
            <p className={styles.titleMobile}>CLIENT LOVE</p>
            <div className={styles.client}>
                <img data-stretch="false"
                     data-src="https://images.squarespace-cdn.com/content/v1/61608afa985367524123800b/3acd229a-3b83-4119-917a-5bbd53ad2ad8/Screen+Shot+2022-10-27+at+12.03.38+PM.png"
                     data-image="https://images.squarespace-cdn.com/content/v1/61608afa985367524123800b/3acd229a-3b83-4119-917a-5bbd53ad2ad8/Screen+Shot+2022-10-27+at+12.03.38+PM.png"
                     data-image-dimensions="504x504" data-image-focal-point="0.5,0.5" alt="" data-load="false"
                     elementtiming="system-image-block"
                     src="https://images.squarespace-cdn.com/content/v1/61608afa985367524123800b/3acd229a-3b83-4119-917a-5bbd53ad2ad8/Screen+Shot+2022-10-27+at+12.03.38+PM.png"
                     width="504" height="504" sizes="100vw"
                     style={{display: 'block', objectFit: 'cover', objectPosition: '50% 50%'}}
                     loading="lazy" decoding="async" data-loader="sqs"/>
            </div>
            <div className={styles.textWrapper}>
                <p className={styles.title}>CLIENT LOVE</p>
                <p className={styles.text}>"ScribblePie made my website stand out from the rest. They guided me and made it easy for us to
                    collaborate and exchange ideas. I can't say enough great things about the process, design team, and
                    final product; a user experience that's unique and exceptional."
                    <br/>
                    - Kendall Steinmetz, Arque Jewelry</p>
            </div>
        </div>
    );
};

export default SectionThree;