import styles from "./SectionOne.module.scss"
import Illustration from "../../../../icons/illustration2.jpg";
import {ReactComponent as TextRing} from "../../../../icons/textRing.svg";
import Button from "../../../../components/button/Button";

const SectionOne = () => {
    return (
        <div className={styles.root}>
            <img className={styles.illustration} src={Illustration}/>
            <div className={styles.descContainer}>
                <p className={styles.tagline}>
                    Design
                    <span className={styles.sparkle}>✦</span>Branding
                    <span className={styles.sparkle}>✦</span>Web Development
                    <span className={styles.sparkle}>✦</span>Mobile Development
                    <span className={styles.sparkle}>✦</span>Digital Marketing
                </p>
                <p className={styles.title}>WE HELP YOU LAUNCH YOUR <span>DREAM.<TextRing/></span></p>
                <p className={styles.text}>We’re passionate about working with industry disruptors who aren’t afraid of
                    taking risks; those
                    who champion diversity, inclusion and empower their clients and communities to be the best
                    version of themselves. </p>
                <Button text="Learn More" onClick={() => {
                }} color="primary"/>
            </div>
        </div>
    );
};

export default SectionOne;