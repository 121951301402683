import Illustration from "../../../../icons/illustration2.jpg";
import styles from './SectionTwo.module.scss'
import Button from "../../../../components/button/Button";

const SectionTwo = () => {
    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.desc}>
                    <p className={styles.title}>GET YOUR PROJECT DONE WITH US</p>
                    <p className={styles.text}>Your all-in-one business solution! we provide all the services your business needs under one roof.</p>
                    <Button text="Learn More" onClick={()=>{}} color="primary"/>
                </div>
                <img className={styles.illustration} src={Illustration}/>
            </div>
        </div>
    );
};

export default SectionTwo;