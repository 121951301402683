import SectionOne from "./sections/sectionOne/SectionOne";
import styles from "./Home.module.scss"
import SectionTwo from "./sections/sectionTwo/SectionTwo";
import SectionThree from "./sections/sectionThree/SectionThree";

const Home = () => {
    return (
        <div className={styles.root}>
            <SectionOne/>
            <SectionTwo/>
            <SectionThree/>
        </div>
    )
}
export default Home