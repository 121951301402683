import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Aboutus from "./pages/aboutus/aboutus";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import "./index.scss"
import Services from "./pages/services/services";


function App() {
  return (
      <Router>
        <Header/>
        <Routes className="page">
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<Aboutus/>}/>
          <Route path="/services" element={<Services/>}/>
        </Routes>
        <Footer/>
      </Router>
  );
}

export default App;
