import {NavLink, useLocation} from "react-router-dom";
import {ReactComponent as Instagram} from "../../icons/instagram.svg";
import {ReactComponent as Facebook} from "../../icons/facebook.svg";
import {ReactComponent as Linkedin} from "../../icons/linkedin.svg";
// import {number, email} from "../../constants";
import {ReactComponent as Logo} from "../../icons/logo.svg";
import styles from "./Header.module.scss"
import useWindowResize from "../../hooks/use-window-resize";
import {useEffect, useState} from "react";
import classNames from "classnames";


const menuItems = [
    // {
    //     name: "Косметология",
    //     link: "/cosmetology",
    //     clickable: false,
    //     subsections: [
    //         "Инъекции ботокса",
    //         "Увеличение и моделирование щек",
    //         "Филлеры (гиалуроновая кислота)",
    //         "Мезотерапия",
    //         "Подтяжка шеи, рук, пальцев и морщин",
    //     ]
    // },
    {
        name: "Home",
        clickable: true,
        link: "/",
        subsections: "",
    },
    {
        name: "About us",
        clickable: true,
        link: "/about",
        subsections: "",
    },
    {
        name: "Services",
        clickable: true,
        link: "/services",
        subsections: ""
    },
    // {
    //     name: "Contact us",
    //     clickable: true,
    //     link: "/contact",
    //     subsections: ""
    // },
]


function Header() {
    const location = useLocation()
    const {width} = useWindowResize();

    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(()=>{
        setMenuOpen(false)
    },[location.pathname])
    return (
        <header className={styles.root}>
            {width > 768 &&
            <nav className={styles.webHeader}>
                <div className={styles.socials}>
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.linkedin.com/company/102080105/admin/feed/posts/"
                       className={styles.icon}>
                        <Linkedin/>
                    </a>
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.instagram.com/techity.space?igsh=Y2s5NWJ2dTgwa2kw&utm_source=qr"
                       className={styles.icon}>
                        <Instagram/>
                    </a>
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://www.facebook.com/share/btEVAxpxikfme9qs/?mibextid=WC7FNe" className={styles.icon}>
                        <Facebook/>
                    </a>
                </div>
                <div className={styles.centerPart}>
                    <div className={styles.logo}><Logo/></div>
                    <div className={styles.content}>
                        <ul className={styles.menuItems}>
                            {menuItems.map((item) => (
                                <li key={item.name}
                                    className={`${location?.pathname === item.link ? styles.activeItem : styles.item}`}>
                                    <NavLink to={item.clickable ? item.link : location?.pathname}>
                                        {item.name}
                                    </NavLink>
                                    {!!item.subsections && <ul className={styles.subsections}>
                                        {item.subsections.map((subsection, index) => {
                                            return (
                                                <li className={styles.subsection}><NavLink to={`${item.link}/${index}`}>
                                                    {subsection}
                                                </NavLink></li>
                                            )
                                        })}
                                    </ul>}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={styles.contact}>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        className={styles.callButton}
                        href="https://calendly.com/techityspace?fbclid=PAAabOx-DMF1VmQIwbc7Zphx_fj_kOjM4zE6aS1nWgl8dldNH2E_GtDfqNxHQ_aem_ASgld6ZU3EPmYjr9Y4S-4Wi6Ci88p-BxY4a8KA7zkk1m8KYP8bWwyRqQACaOjZSG5yY">
                        Book A Call
                    </a>

                    {/*<a href={`tel:${number.replace(/[{} ]/g, '')}`} className={styles.tel}>
                    {number}
                </a>
                <a href={`mailto:${email}`} className={styles.email}>
                    {email}
                </a>*/}
                </div>
            </nav>
            }
            {width <= 768 &&
            <nav className={styles.mobileHeader}>
                <div/>
                <div className={styles.logo}><Logo/></div>
                <div className={classNames(styles.burgerMenu, {[styles.open]: menuOpen})}
                     onClick={() => menuOpen ? setMenuOpen(false) : setMenuOpen(true)}>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div>
                <div className={classNames(styles.content, {[styles.openContent]: menuOpen})}>
                    <ul className={styles.menuItems}>
                        {menuItems.map((item) => (
                            <li key={item.name}
                                className={`${location?.pathname === item.link ? styles.activeItem : styles.item}`}>
                                <NavLink to={item.clickable ? item.link : location?.pathname}>
                                    {item.name}
                                </NavLink>
                                {!!item.subsections && <ul className={styles.subsections}>
                                    {item.subsections.map((subsection, index) => {
                                        return (
                                            <li className={styles.subsection}><NavLink to={`${item.link}/${index}`}>
                                                {subsection}
                                            </NavLink></li>
                                        )
                                    })}
                                </ul>}
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
            }

        </header>
    );
}

export default Header;
