import styles from './services.module.scss'
import Illustration from "../../icons/illustration2.jpg";
import {useState} from "react";
import classNames from "classnames";

const services = [
    {
        id: 1,
        title: "Design",
        text: "We specialize in turning ideas into visually captivating realities. Our team is dedicated to bringing your concepts to life with creativity and precision."
    },
    {
        id: 2,
        title: "Branding",
        text: "Crafting a new brand or refining your current one? We've got you covered. Elevate your visual identity and strategy with our expertise in branding."
    },
    {
        id: 3,
        title: "Web Development",
        text: "We specialize in crafting dynamic websites tailored to your needs. From custom development and CMS solutions to e-commerce platforms, we bring your digital vision to life with precision and expertise. Let's build your online success story together!"
    },
    {
        id: 4,
        title: "Mobile Development",
        text: "We specialize in crafting intuitive and high-performance mobile applications that resonate with users. From concept to launch, we're with you every step of the way"
    },
    {
        id: 5,
        title: "Digital Marketing",
        text: "Boost your brand with Techity Space's all-inclusive Digital Marketing services. From strategic planning to dynamic campaigns, we encompass the full spectrum of digital solutions to enhance your online presence."
    },
]
const Services = () => {
    const [active, setActive] = useState(0)

    return (
        <div className={styles.root}>
            <img className={styles.illustration} src={Illustration} alt="img"/>
            <div className={styles.services}>
                {services.map((item) => (
                    <div key={item.id}
                         className={styles.item}
                         onClick={() => {
                             active === item.id ? setActive(0) : setActive(item.id)
                         }}>
                        <div className={styles.itemTitle}>
                            <div className={classNames(styles.actionButton, {[styles.minus]: active === item.id})}>
                                <span/>
                                <span/>
                            </div>
                            <p>{item.title}</p>
                        </div>
                        <div className={classNames(styles.textWrapper, {[styles.visible]: active === item.id})}>
                            <p className={styles.text}>{item.text}</p>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;