import {useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";

import styles from './Footer.module.scss'

const menuItems = [
    // {
    //     name: "Стоматология",
    //     link: "/dentistry",
    //     subsections: ""
    // },
    // {
    //     name: "Косметология",
    //     link: "/cosmetology",
    //     subsections: ""
    // },
    {
        name: "Home",
        clickable: true,
        link: "/",
        subsections: "",
    },
    {
        name: "About us",
        clickable: true,
        link: "/about",
        subsections: "",
    },
    {
        name: "Services",
        clickable: true,
        link: "/services",
        subsections: ""
    },
    {
        name: "Contact us",
        clickable: true,
        link: "/contact",
        subsections: ""
    },
]

const links = [
    {
        name: "Instagram",
        link: "/"
    },
    {
        name: "Linkedin",
        link: "/"
    },
    {
        name: "Facebook",
        link: "/"
    }
]

function Footer() {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location?.pathname])

    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                <div className={styles.column}>
                    <p className={styles.instagram}>@techity.space</p>
                    <div className={styles.goal}>
                        <p className={styles.text}> From web development to design and branding, our services set the stage for your success.</p>

                    </div>
                </div>

                <nav>
                    <ul className={styles.menuItems}>
                        <p className={styles.title}>NAVIGATION</p>
                        {menuItems.map((item) => (
                            <li key={item.name}
                                className={`${location?.pathname === item.link ? styles.activeItem : styles.item}`}>
                                <NavLink to={item.link}>
                                    {item.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <ul className={styles.menuItems}>
                        <p className={styles.title}>FOLLOW</p>
                        {links.map((item) => (
                            <li key={item.name}
                                className={styles.item}>
                                <a href={item.link}>
                                    {item.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <p className={styles.copy}>
                &copy; Techity Space {new Date().getFullYear()}. all rights reserved
            </p>
        </footer>
    );
}

export default Footer;
